import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Ion implanters`}</h4>
    <p>{`Ion implanters embed dopant substances into silicon wafers to give different parts of the wafer different levels of semiconductivity to make functional transistors in chips. Different types of implanters are used for different purposes. Low-to-medium-current ion implanters and high-current ion implanters are most commonly used, with high-current ion implanters capable of greater throughput. Meanwhile, high-voltage ion implanters can implant ions deeply into silicon, and ultra-high-dose doping implanters can achieve greater dopant density than the other tools. The United States is the dominant producer of ion implanters, with Japan and Taiwan rounding out most of global market share.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      